export class IdlesService {
    static getIdles() {
        const idles = localStorage.getItem('idles');
        if (idles && idles.length) {
            return JSON.parse(idles);
        }
        return [];
    }
    static hasIdle(id) {
        const idles = IdlesService.getIdles();
        return Boolean(idles.find((i) => i.id === id));
    }
    static addIdle(id) {
        const idles = IdlesService.getIdles();
        const item = { id, active: true };
        localStorage.setItem('idles', JSON.stringify([...idles, item]));
    }
    static removeIdle(id) {
        const idles = IdlesService.getIdles();
        const newIdles = idles.filter((i) => i.id !== id);
        localStorage.setItem('idles', JSON.stringify(newIdles));
    }
    static setActive(id, active) {
        const idles = IdlesService.getIdles();
        const index = idles.findIndex((i) => i.id === id);
        if (index >= 0) {
            idles.splice(index, 1, {
                id,
                active
            });
            localStorage.setItem('idles', JSON.stringify(idles));
        }
    }
    static getActives() {
        const idles = IdlesService.getIdles();
        return idles.filter((i) => i.active);
    }
    static removeAll() {
        localStorage.setItem('idles', JSON.stringify([]));
    }
}
