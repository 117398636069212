import { GenericService } from './generic.service';
import apiClient from '@/services/api/client/apiClient';
export class PatientDocumentService extends GenericService {
    constructor(patientId) {
        super(`v1/patients/${patientId}/documents`);
    }
    async fetchBase64Pdf(id, params = {}) {
        return await apiClient.get(`${this.endpoint}/${id}`, {
            ...params,
            headers: {
                ...(params.headers || {}),
                Accept: 'application/pdf;base64'
            }
        });
    }
    async getItemHtml(documentId, itemId, params = {}) {
        return await apiClient.get(`${this.endpoint}/${documentId}/items/${itemId}`, {
            ...params,
            headers: {
                ...(params.headers || {}),
                Accept: 'text/html'
            }
        });
    }
}
