import { GenericService } from '@/services/api/generic.service';
export class TriageStatusService extends GenericService {
    constructor() {
        super('v1/triage-statuses');
    }
    async getTriageStatusByName(name, clinicId) {
        const requestConfig = {
            params: {
                'filter[clinic_id]': clinicId
            }
        };
        const triageStatuses = (await this.index(requestConfig)).data;
        const triageStatus = triageStatuses.find((status) => status.name === name);
        if (!triageStatus) {
            throw new Error(`Triage status of name ${name} not found`);
        }
        return triageStatus;
    }
}
