import apiClient from '@/services/api/client/apiClient';
export class DocumentService {
    endpoint = 'v1/documents';
    async requestOTPForDocument(id) {
        return await apiClient.post(`${this.endpoint}/${id}/otp-request`);
    }
    async fetchBase64Pdf(id, params = {}) {
        return await apiClient.get(`${this.endpoint}/${id}`, {
            ...params,
            headers: {
                ...(params.headers || {}),
                Accept: 'application/pdf;base64'
            }
        });
    }
}
