type UnitOfTime = 'days' | 'weeks' | 'months';

export const useIncrementDate = (increment: number, unitOfTime: UnitOfTime, initialDate = new Date()): Date | null => {
  let newDate;
  switch (unitOfTime) {
  case 'days':
    newDate = addDays(increment, initialDate);
    break;
  case 'weeks':
    newDate = addWeeks(increment, initialDate);
    break;
  case 'months':
    newDate = addMonths(increment, initialDate);
    break;
  }
  return newDate;
};

const addDays = (days: number, initialDate = new Date()): Date => {
  const date = new Date(initialDate);
  date.setDate(date.getDate() + +days);
  return date;
};

const addWeeks = (weeks: number, initialDate = new Date()): Date => {
  const date = new Date(initialDate);
  date.setDate(date.getDate() + +weeks * 7);
  return date;
};

const addMonths = (months: number, initialDate = new Date()): Date => {
  const date = new Date(initialDate);
  date.setMonth(date.getMonth() + +months);
  return date;
};
