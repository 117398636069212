export const RESOLVE_REASON_MAX_LENGTH = 500;
export const REJECT_REASON_MAX_LENGTH = 500;
export const CANCEL_REASON_MAX_LENGTH = 500;
export const REASON_MAX_LENGTH = 500;
export var ConsultTabStatus;
(function (ConsultTabStatus) {
    ConsultTabStatus[ConsultTabStatus["ALL_CONSULTS"] = 0] = "ALL_CONSULTS";
    ConsultTabStatus[ConsultTabStatus["UNSCHEDULED"] = 1] = "UNSCHEDULED";
    ConsultTabStatus[ConsultTabStatus["SCHEDULED"] = 2] = "SCHEDULED";
    ConsultTabStatus[ConsultTabStatus["CONSULTING"] = 3] = "CONSULTING";
    ConsultTabStatus[ConsultTabStatus["REVIEWING"] = 4] = "REVIEWING";
    ConsultTabStatus[ConsultTabStatus["REJECTED"] = 5] = "REJECTED";
})(ConsultTabStatus || (ConsultTabStatus = {}));
