import apiClient from '@/services/api/client/apiClient';
import { GenericService } from '@/services/api/generic.service';
export class OrganisationExternalPatientService extends GenericService {
    constructor(organisationId) {
        super(`v1/organisations/${organisationId}/external-patients`);
    }
    async attach(patientId, changes, params = {}) {
        return await apiClient.patch(`${this.endpoint}/${patientId}/attach-patient`, changes, params);
    }
    async search(params = {}) {
        return (await apiClient.get(`${this.endpoint}/search-patient`, params)).data;
    }
}
